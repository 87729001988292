import { PAGE_PROPS, PAGE_DEFAULT_PROPS } from 'constants/prop-types'
import { HOMEPAGE_REFRESHMENT_SPANISH } from 'constants/experiments'

import React, { Suspense } from 'react'
import dynamic from 'next/dynamic'
import DocumentHead from 'components/head'
import { logError, logNotFound } from 'server/utils/logger'
import {
  OptimizelyExperiment,
  OptimizelyVariation,
} from 'components/optimizely'
import SuspenseFallback from 'components/suspense-fallback'
import { getCommonServerSideProps } from 'utils/server'
import { LAYOUT_TYPES } from '@typeform/ginger/dist/components/footer/constants'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import { routes } from 'utils/routes'

import ContentfulService from '../services/contentful-service'

const Homepage = dynamic(() => import('components/homepage'))
const HomepageRefresh = dynamic(() => import('components/homepage-refresh'))

const HomePage = props => {
  const { locale, availableLocales, pageContent, currentUrl } = props

  return (
    <>
      <DocumentHead
        {...pageContent.metaTags}
        currentUrl={currentUrl}
        availableLocales={availableLocales}
        isRetainActive
      />
      <Suspense fallback={<SuspenseFallback />}>
        {locale === LANGUAGE_CODES.english ? (
          <HomepageRefresh />
        ) : (
          <OptimizelyExperiment experimentId={HOMEPAGE_REFRESHMENT_SPANISH.ID}>
            <OptimizelyVariation
              variationId={HOMEPAGE_REFRESHMENT_SPANISH.VARIATIONS.CONTROL}
            >
              <Homepage />
            </OptimizelyVariation>
            <OptimizelyVariation
              variationId={HOMEPAGE_REFRESHMENT_SPANISH.VARIATIONS.VARIATION}
              default
            >
              <HomepageRefresh />
            </OptimizelyVariation>
          </OptimizelyExperiment>
        )}
      </Suspense>
    </>
  )
}

export const getServerSideProps = async ctx => {
  try {
    const { locale, resolvedUrl, req } = ctx
    const { path } = req

    const [pageContent, commonServerSideProps] = await Promise.all([
      ContentfulService.getPage({ url: '/', locale }),
      getCommonServerSideProps(ctx),
    ])

    if (!pageContent) {
      logNotFound({
        message: 'No page content for Home Page',
        additionalInfo: { resolvedUrl, locale, section: 'homepage' },
      })
      return {
        notFound: true,
      }
    }

    if (path === routes().try.home) {
      commonServerSideProps.appConfig.headerProps = {
        ...commonServerSideProps.appConfig.headerProps,
        enableMinimalMenu: true,
      }
      commonServerSideProps.appConfig.footerProps = {
        ...commonServerSideProps.appConfig.footerProps,
        layoutType: LAYOUT_TYPES.COMPACT,
      }
    }

    if (path === routes().home && locale === LANGUAGE_CODES.english) {
      commonServerSideProps.appConfig.headerProps = {
        ...commonServerSideProps.appConfig.headerProps,
        shouldUseNowYouKnowLogo: true,
      }
    }

    return {
      props: {
        ...commonServerSideProps,
        pageContent: {
          ...pageContent,
        },
      },
    }
  } catch (error) {
    logError({
      message: 'Error in homepage getServerSideProps',
      error,
      additionalInfo: { section: 'homepage' },
    })

    return {
      notFound: true,
    }
  }
}

HomePage.propTypes = {
  ...PAGE_PROPS,
}

HomePage.defaultProps = {
  ...PAGE_DEFAULT_PROPS,
}

HomePage.displayName = 'HomePage'

export default HomePage
